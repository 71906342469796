import React from 'react';
import { Trans, useTranslation } from "react-i18next"

import SEO from '../../components/seo';
import styles from './job.module.css';

const JobPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title="Asistentka generálního ředitele" />
      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>Asistentka generálního ředitele</h1>
          <Trans i18nKey="careers.assistantGeneralManager.text1">
            <p className={styles.text}>Hledáme kolegyni na pozici <b>Asistentka generálního ředitele</b>. Chceš být členem týmu a pravou rukou CEO úspěšného startupu? Baví tě komunikace s lidmi a ráda se vzděláváš? Tak jsi tady správně!</p>
            <p className={styles.text}>
            NutritionPro – jsme úspěšný a rostoucí startup z oblasti FoodTech. Vyvinuli jsme algoritmus, který na základě tvých tělesných údajů vytvoří jídelníček na míru. A to je teprve začátek - přesně spočítaná jídla pro tebe taky uvaříme a dovezeme!
            </p>
          </Trans>

          <Trans i18nKey="careers.assistantGeneralManager.list1">
            <h3 className={styles.textSubHeader}>Co u nás budeš dělat?</h3>
            <ul className={styles.textUl}>
              <li className={styles.newList}>
              Příprava interních reportů a statistik pro CEO
              </li>
              <li className={styles.newList}>
              Administrativa a příprava dokumentace
              </li>
              <li className={styles.newList}>
              Přehled, koordinace a příprava podkladů pro schůzky
              </li>
              <li className={styles.newList}>
              Zabezpečovani komunikace mezi CEO a liniovými manažeri
              </li>
              <li className={styles.newList}>
              Pravidelná komunikace s investory společnosti
              </li>
              <li className={styles.newList}>
              Spolupráce na rozvojových projektech společnosti
              </li>
            </ul>
          </Trans>

          <Trans i18nKey="careers.assistantGeneralManager.list2">
            <h3 className={styles.textSubHeader}>Co od tebe očekáváme?</h3>
            <ul className={styles.textUl}>
              <li className={styles.newList}>
                Zkušenosti z obdobné pozice min. 5 let
              </li>
              <li className={styles.newList}>
                Vynikající znalost Google Sheets a MS Excelu
              </li>
              <li className={styles.newList}>
                Schopnost řešit problémy a dotahovat věci do konce
              </li>
              <li className={styles.newList}>
                Organizační a komunikační dovednosti 
              </li>
              <li className={styles.newList}>
                Proaktivní přístup, zodpovědnost, spolehlivost, loajalita
              </li>
              <li className={styles.newList}>
                Odolnost vůči stresu a flexibilitu 
              </li>
            </ul>
          </Trans>

          <Trans i18nKey="careers.assistantGeneralManager.list3">
            <h3 className={styles.textSubHeader}>Jazykové znalosti:</h3>
            <ul className={styles.textUl}>
              <li className={styles.newList}>
                Dokonalou češtinu slovem i písmem
              </li>
              <li className={styles.newList}>
                Znalost ruštiny je výhoda, ale ne nutnost
              </li>
              <li className={styles.newList}>
                Komunikativní angličtina je bonus navíc
              </li>
            </ul>
          </Trans>

          <Trans i18nKey="careers.assistantGeneralManager.list4">
            <h3 className={styles.textSubHeader}>Co získáš od nás?</h3>
            <ul className={styles.textUl}>
              <li className={styles.newList}>
              Motivační finanční ohodnocení a měsíční bonusy
              </li>
              <li className={styles.newList}>
              Mentoring od zakladatele společnosti 
              </li>
              <li className={styles.newList}>
              Sick-days
              </li>
              <li className={styles.newList}>
              Příspěvky na vzdělávací kurzy
              </li>
              <li className={styles.newList}>
              Multisport kartu plně hrazenou firmou
              </li>
              <li className={styles.newList}>
              Firemní obědy zdarma
              </li>
              <li className={styles.newList}>
              Sídlíme v moderních kancelářích v srdci Karlína, takže jako bonus tě čeká i skvělé prostředí pro networking, neformální atmosféra bez dress code a velká terasa s výhledem na Prahu!
              </li>
            </ul>
          </Trans>

          <h3 className={styles.textSubHeader}>
            {t("careers.textSubHeader2")}
          </h3>
          <h3 className={styles.textSubHeader}>
            {t("careers.textSubHeader3")}
          </h3>
          <a className={styles.jobsEmail} href="mailto:jobs@nutritionpro.cz">
            jobs@nutritionpro.cz
          </a>
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            {t("careers.backButton")}
          </a>
        </div>
      </div>
    </>
  )
}

export default JobPage